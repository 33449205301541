<template>
  <div class="main">
    <div class="topbar">
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <template>
              <Input v-model="operator"
                     placeholder="操作人"
                     class="m-r-10"
                     style="width:160px" />
              <Input v-model="content"
                     placeholder="操作内容"
                     class="m-r-10"
                     style="width:160px" />
              <DatePicker :value="date"
                          type="daterange"
                          :start-date="new Date()"
                          placeholder="请选择时间范围"
                          style="width:260px"
                          @on-change="onChangeDate">
              </DatePicker>
            </template>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/log/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/log/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <template>
          <div class="flex">
            系统日志
            <!-- <Button type="primary" class="m-r-10">
              <Icon custom="i-icon icon-tianjia1" size="16"></Icon>
              添加
            </Button> -->
          </div>
        </template>
      </div>
      <div class="flex">
        <div class="table">
          <Table ref="table"
                 :width="tableWidth"
                 :height="tableHeight"
                 :columns="columnsPlan"
                 :data="data"
                 border
                 stripe
                 :loading="tableLoading"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
            <template slot-scope="{ row }"
                      slot="operation">
              <Button @click.stop=""
                      type="text">处理</Button>
            </template>
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkPermi } from '@/utils/permission'
export default {
  components: {},
  data () {
    return {
      operator: '',
      content: '',
      date: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsPlan: [
        {
          title: '操作人',
          key: 'account',
          tooltip: true,
          width: 200,
          align: 'center'
        },
        {
          title: 'IP地址',
          key: 'ip',
          tooltip: true,
          width: 200,
          align: 'center'
        },
        {
          title: '操作时间',
          key: 'createTime',
          tooltip: true,
          width: 220,
          align: 'center'
        },
        {
          title: '登录位置',
          key: 'address',
          tooltip: true,
          width: 260,
          align: 'center'
        },
        {
          title: '操作内容',
          key: 'content',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let arr = row.content.split('##').join('<br />')
            return h(
              'span', {
              domProps: {
                innerHTML: arr
              },
            },
            )
          }
        },
      ],
      selectArr: [],
      data: [],
    };
  },
  computed: {

  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    init () {
      this.tableWidth = window.innerWidth - 240
      this.tableHeight = window.innerHeight - 290
      window.onresize = () => {
        this.tableWidth = window.innerWidth - 240
        this.tableHeight = window.innerHeight - 290
      }
      this.getList()
    },
    // 日期选择器回调
    onChangeDate (date) {
      this.date = date
    },
    onClickSet () {

    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        account: this.operator,
        content: this.content,
        startDate: this.date[0],
        endDate: this.date[1]
      }
      this.tableLoading = true
      this.$http.getLogList(params).then((res) => {
        this.data = res.result.notices
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    resetSearchData () {
      this.operator = ''
      this.content = ''
      this.date = []
    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";

.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;

    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
